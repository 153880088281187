<template>
  <header class="mb-4 border-bottom border-secondary">
    <nav class="navbar navbar-expand-lg header__navbar navbar-light" :class="{ 'bg-warning': loggedAsZame }">
      <div class="container">
        <div class="navbar-brand d-flex">
          <a href="https://www.nordictelecom.cz/" target="_blank" class="d-block">
            <img src="@/assets/img/nt_o2_logo_desktop.png" alt="" width="171" height="36" class="d-sm-inline-block d-none">
            <img src="@/assets/img/nt_o2_logo_mobile.png" alt="" width="92" height="36" class="d-inline-block d-sm-none">
          </a>
          <router-link class="d-block py-1 ms-4 text-decoration-none" to="/">
            Samoobsluha<span v-if="loggedAsZame"> (ZAME)</span>
          </router-link>
          <spinner :show="showSpinner"/>
        </div>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarContent">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0 d-flex" v-if="authorized">
            <li class="nav-item me-lg-2" v-if="loggedAsZame">
              <customer-loader />
            </li>
            <li class="nav-item me-lg-2">
              <router-link class="nav-link" to="/">Přehled</router-link>
            </li>
            <li class="nav-item me-lg-2">
              <router-link class="nav-link" to="/vyuctovani">Vyúčtování</router-link>
            </li>
            <li class="nav-item me-lg-2">
              <router-link class="nav-link" to="/platby">Platby</router-link>
            </li>
            <li class="nav-item me-lg-2">
              <router-link class="nav-link" to="/dokumenty">Dokumenty</router-link>
            </li>
            <li>
              <router-link class="nav-link" to="/ucet">
                <div class="d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19"
                       class="replaced-svg_svg">
                    <g>
                      <g>
                        <path fill="none" stroke="#3c1053" stroke-miterlimit="20"
                              d="M9 11A5 5 0 1 0 9 1a5 5 0 0 0 0 10z"></path>
                      </g>
                      <g>
                        <path fill="none" stroke="#3c1053" stroke-miterlimit="20" d="M17 19a8 8 0 1 0-16 0"></path>
                      </g>
                    </g>
                  </svg>
                  <span class="fw-normal ms-1">{{ username }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <a class="nav-link fw-normal" href="/" @click.prevent="logout">
                Odhlásit se
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import {auth} from '@/firebase';
import Spinner from "@/components/Spinner";
import CustomerLoader from "@/components/CustomerLoader";

export default {
  name: "AppHeader",
  components: {CustomerLoader, Spinner},
  inject: ["globalSpinner", "globalFlashMessage", "globalCustomer"],
  props: {
    showSpinner: {
      required: false,
      type: Boolean,
      default: false,
    },
    loggedAsZame: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logout() {
      this.globalSpinner.active = true;
      auth
          .signOut()
          .catch(err => this.globalFlashMessage.data = err)
          .finally(() => this.globalSpinner.active = false)
    }
  },
  computed: {
    authorized() {
      return this.$store.state.authorized;
    },
    username() {
      const customer = this.globalCustomer.data;
      if (this.loggedAsZame && customer !== null) {
        return customer.fullName;
      }
      return this.$store.state.username;
    },
    spinnerActive() {
      return this.globalSpinner.active;
    }
  }
}
</script>

<style scoped>
</style>
