<template>
  <footer class="mt-auto">
    <div class="footer-navigation py-3">
      <div class="container">
        <div class="d-lg-flex">
          <div class="flex-grow-1 row justify-content-center">
            <div class="col-md-6 col-lg py-2 text-center text-md-start">
              <h5 class="footer-navigation-title">Objevte naše služby</h5>
              <ul class="list-unstyled footer-navigation-list">
                <li>
                  <a href="https://www.5gvevzduchu.cz/?_ga=2.104160448.1558896435.1623844001-312031278.1585774156&_gac=1.125697784.1623927120.Cj0KCQjws_r0BRCwARIsAMxfDRgvivQu3LYJNQFS7h8AUy26sTbC3M1hebpPrpM_x1cfCvEQ_Duhje8aAoEvEALw_wcB" target="_blank">Internet</a>
                </li>
                <li>
                  <a href="https://www.koukejkdychces.tv/?_ga=2.104160448.1558896435.1623844001-312031278.1585774156&_gac=1.125697784.1623927120.Cj0KCQjws_r0BRCwARIsAMxfDRgvivQu3LYJNQFS7h8AUy26sTbC3M1hebpPrpM_x1cfCvEQ_Duhje8aAoEvEALw_wcB" target="_blank">Televize</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-lg py-2 text-center text-md-start" v-if="provider && provider.supportLink">
              <h5 class="footer-navigation-title" v-if="provider.code === 'nt'">Poradíme vám</h5>
              <h5 class="footer-navigation-title" v-else>Dokumenty</h5>
              <ul class="list-unstyled footer-navigation-list">
                <li>
                  <a :href="provider.supportLink" target="_blank" v-if="provider.code === 'nt'">Podpora</a>
                  <a :href="provider.supportLink" target="_blank" v-else>Ke stažení</a>
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-lg py-2 text-center text-md-start" v-if="provider">
              <h5 class="footer-navigation-title">{{ provider.name }}</h5>
              <ul class="list-unstyled footer-navigation-list">
                <li v-if="provider.contactAddress">{{ provider.contactAddress }}</li>
                <li v-if="provider.idNumber">IČ {{ provider.idNumber }}</li>
                <li v-if="provider.vatNumber">DIČ {{ provider.vatNumber }}</li>
              </ul>
            </div>
            <div class="col-md-6 col-lg py-2 text-center text-md-start" v-if="provider">
              <h5 class="footer-navigation-title">Kontakty</h5>
              <ul class="list-unstyled footer-navigation-list">
                <li v-if="provider.contactPhone"><i class="bi bi-telephone me-1"></i>{{ provider.contactPhone }}</li>
                <li v-if="provider.contactEmail"><i class="bi bi-envelope me-1"></i>{{ provider.contactEmail }}</li>
              </ul>
            </div>
          </div>
          <div class="p-2 text-center">
            <ul class="list-inline m-0">
              <li class="list-inline-item px-2">
                <a href="https://www.facebook.com/nordictelecomcz" target="_blank"
                   class="footer__socials__link footer__socials__link--facebook">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="25" viewBox="0 0 13 25"
                       class="replaced-svg_svg">
                    <g>
                      <g>
                        <path fill="#6b6b6b"
                              d="M8.505 5.82v3.385h4.325l-.96 4.349H8.506v11.113h-4.81V13.552H.33V9.205h3.366V5.822S3.267 1.213 7.543.5h5.287v3.87h-2.403S8.579 4.32 8.505 5.82z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="list-inline-item px-2">
                <a href="https://www.linkedin.com/company/nordic-telecom-s.r.o." target="_blank"
                   class="footer__socials__link footer__socials__link--linkedin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"
                       class="replaced-svg_svg">
                    <g>
                      <g>
                        <path fill="#6b6b6b"
                              d="M5.17 7h-5v16h5zM2.671 5.5A2.5 2.5 0 1 0 2.667.499 2.5 2.5 0 0 0 2.671 5.5zm19.999 8.938C22.67 10.233 21.8 7 17.091 7c-2.262 0-3.779 1.294-4.4 2.522h-.063V7.387H8.17V23h4.645v-7.722c0-2.036.37-4.01 2.789-4.01 2.386 0 2.416 2.33 2.416 4.14V23h4.65z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>

              <li class="list-inline-item px-2">
                <a href="https://www.youtube.com/channel/UCIqzc52EjErzRdYsVLtj0kQ" target="_blank"
                   class="footer__socials__link footer__socials__link--youtube">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21"
                       class="replaced-svg_svg">
                    <g>
                      <g>
                        <path fill="#6b6b6b"
                              d="M12.244 6.233l7.45 3.82-7.45 4.11zM15.102 0h-.012S9.103 0 5.115.292A4.042 4.042 0 0 0 2.257 1.52c-.854.88-1.134 2.878-1.134 2.878A44.856 44.856 0 0 0 .84 9.09v2.2a44.86 44.86 0 0 0 .283 4.693s.28 2 1.134 2.878c1.083 1.154 2.508 1.118 3.146 1.239 2.279.223 9.691.292 9.691.292s5.992-.01 9.984-.303a4.047 4.047 0 0 0 2.858-1.228c.854-.88 1.133-2.878 1.133-2.878.177-1.558.271-3.124.284-4.693V9.092a44.857 44.857 0 0 0-.284-4.692s-.279-2-1.133-2.878A4.047 4.047 0 0 0 25.077.293C21.087.002 15.103.002 15.103.002z"></path>
                      </g>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-copyright p-3">
      <div class="container">
        <ul class="list-inline m-0">
          <li class="list-inline-item me-4 text-muted">
            Copyright {{ year }} Nordic Telecom. Všechna práva vyhrazena.
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  inject: ["globalCustomer", "loadCustomerSnapshot"],
  data() {
    return {
      year: (new Date()).getFullYear(),
    }
  },
  computed: {
    provider() {
      if (this.globalCustomer.data === null) {
        this.loadCustomerSnapshot();
        return null;
      }

      if (this.globalCustomer.data.provider === undefined) {
        return null;
      }

      return this.globalCustomer.data.provider;
    }
  }
}
</script>

<style scoped>
  footer {
    background-color: #f3f3f3;
  }

  .footer-copyright {
    background-color: #d0d0d0;
    font-size: 0.75rem;
  }

  .footer-navigation-list > li {
    font-size: .9rem;
  }

  .footer-navigation-list > li > a {
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    color: #333;
    padding: 5px 0;
    line-height: 2rem;
  }

  .footer-navigation-title {
    font-size: .8rem;
    margin-bottom: .5rem;
  }
</style>
